import {Injectable} from "@angular/core";
import {IGridRequestPayload, IPagedResponse} from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {map, share} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {IEventLogChange, IEventLogError, IEventLogReportParams} from "@core/interfaces/event-log";
import {IDictionaryBody, IMultiSelectItem, ISelectItem} from "web-frontend-component-library/interfaces";
import {FileService} from "@core/services/file.service";

@Injectable({
  providedIn: 'root'
})
export class EventLogApiService {

  constructor(
    private HttpClient: HttpClient,
    private fileService: FileService
  ) {
  }

  // Журнал событий. Получение дополнительной информации по конкретному событию
  public getEventInfo(eventLogId: number): Observable<IEventLogChange[]> {
    return this.HttpClient.get<IEventLogChange[]>(
      `${environment.API_URL}/event/log/${eventLogId}/infos`)
      .pipe(share());
  }

  // Журнал событий. Получение информации об ошибках по конкретному событию
  public getEventErrors(eventLogId: number): Observable<IEventLogError[]> {
    return this.HttpClient.get<IEventLogError[]>(
      `${environment.API_URL}/event/log/${eventLogId}/errors`)
      .pipe(share());
  }

  // грид Журнал событий
  public getEventLogGrid(requestPayload: IGridRequestPayload): Observable<any> {
    return this.HttpClient.post(
      `${environment.API_URL}/event/log/grid`, requestPayload)
      .pipe(share());
  }

  private readonly requestPayload: IGridRequestPayload = {
    sortings: [{field: "id", direction: "asc", index: 0}]
  };

  public getCategoryEventList(requestPayload: IGridRequestPayload = this.requestPayload)
    : Observable<IMultiSelectItem[]> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/event/log/category/event/grid`,
      requestPayload
    ).pipe(
      map((item: IPagedResponse) => {
        const list = item && item.elements && item.elements.length > 0 ? item.elements : [];
        const result: IMultiSelectItem[] = list && list.length > 0
          ? list.map((l) => {return {key: l.id, value: l.name};}) : [];
        return result;
      }), share());
  }

  public getCategoryEventDictionary(requestPayload: IGridRequestPayload)
    : Observable<IDictionaryBody> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/event/log/category/event/grid`,
      requestPayload
    ).pipe(
      map((item: IPagedResponse) => {
        let result: IDictionaryBody = {
          elements: [],
          totalElements: item && item.total ? item.total : null,
          pageSize: item && item.pageSize ? item.pageSize : null,
          pageNumber: item && item.pageNumber ? item.pageNumber : null,
          totalPages: item && item.totalPages ? item.totalPages : null
        };
        const list = item && item.elements && item.elements.length > 0 ? item.elements : [];
        result.elements = list && list.length > 0
          ? list.map((l) => {
            return { key: l.id, value: l.name };
          }) : [];
        return result;
      }), share());
  }

  public getTypeEventList(requestPayload: IGridRequestPayload = this.requestPayload)
    : Observable<IMultiSelectItem[]> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/event/log/type/event/grid`,
      requestPayload
    ).pipe(
      map((item: IPagedResponse) => {
        const list = item && item.elements && item.elements.length > 0 ? item.elements : [];
        const result: IMultiSelectItem[] = list && list.length > 0
          ? list.map((l) => {return {key: l.id, value: l.name};}) : [];
        return result;
      }), share());
  }

  public getTypeEventDictionary(requestPayload: IGridRequestPayload)
    : Observable<IDictionaryBody> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/event/log/type/event/grid`,
      requestPayload
    ).pipe(
      map((item: IPagedResponse) => {
        let result: IDictionaryBody = {
          elements: [],
          totalElements: item && item.total ? item.total : null,
          pageSize: item && item.pageSize ? item.pageSize : null,
          pageNumber: item && item.pageNumber ? item.pageNumber : null,
          totalPages: item && item.totalPages ? item.totalPages : null
        };
        const list = item && item.elements && item.elements.length > 0 ? item.elements : [];
        result.elements = list && list.length > 0
          ? list.map((l) => {
            return { key: l.id, value: l.name };
          }) : [];
        return result;
      }), share());
  }

  public getEventLogReportList(): Observable<ISelectItem[]> {
    return this.HttpClient.get<ISelectItem[]>(
      `${environment.API_URL}/report/list`)
      .pipe(share());
  }

  public getEventLogReport(params: IEventLogReportParams) {
    return this.fileService.downloadFile(
      `${environment.API_URL}/report/generate`, null, params, true);
  }
}
