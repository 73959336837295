<div class="headerClass">
  <div class="header-left">
    <lib-button class="header-menu"
                [buttonStyles]="['container']"
                (OnClick)="toggleMenu(true)">
      <span class="font-icons icon-menu"></span>
    </lib-button>
    <a [routerLink]="mainUrl"
       class="header-home">
      <span class="font-icons icon-home"></span>
    </a>
    <lib-breadcrums [breadcrumbs]="breadcrumbs$ | async"
                    class="header-breadcrums"></lib-breadcrums>
  </div>
  <div class="header-right">
    <lib-button class="header-docs"
                [buttonStyles]="['icon']"
                icon="icon-guide_header"
                iconSize="25"
                (OnClick)="downloadGuide()">
    </lib-button>
    <app-header-support class="header-support"></app-header-support>
  </div>
</div>
<app-menu-auth></app-menu-auth>