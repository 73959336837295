import { IAdminNsiDictionary } from "@core/interfaces/admin-nsi-dictionaries";
import { ACCESS_TREE_OBJECT_TYPE } from "web-frontend-component-library/enums";

export const ACCESS_OBJECTS_KEY = 'Auth-AccessObjects';
export const ACCESS_RIGHTS_KEY = 'Auth-AccessRights';
export const USER_MENU_KEY = 'Auth-UserMenuKey';
export const MENU_OPENED_KEY = 'Auth-MenuOpenedKey';
export const USER_INFO_KEY = 'Auth-UserInfo';
export const REDIRECT_TO_KEY = 'Auth-RedirectTo';
export const AUTH_KEY = 'Auth-Auth';
export const SYSTEM_PARAMS_KEY = 'Auth-SystemParams';
export const PUBLIC_SYSTEM_PARAMS_KEY = 'Auth-PublicSystemParams';

export const MENU_URLS = {
  m_NSI: '/access-admin/nsi',
  m_User: '/access-admin/users',
  m_System: '/access-admin/system-parameters',
  m_EventLog: '/access-admin/monitoring'
}

export const MENU_ICONS = {
  m_NSI: 'icon-section_nsi',
  m_User: 'icon-section_usr',
  m_System: 'icon-section_sysparam',
  m_EventLog: 'icon-section_mon'
}

export const MENU_OPENED_URLS = [
  '/access-admin'
]

export const passwordPatterns = {
  // глобал флаг обязателен для regexp,
  // используемых при подсчете минимального
  // количества символов
  AZ: /[A-Z]+/g,
  az: /[a-z]+/g,
  num: /[0-9]+/g,
  spec: /[@#$\-_=;:<>`~!"№%^?&*()+\\|/.,'[\]{}]+/g,
  rus: /[^\sА-Яа-яЁё]/
}

export const password_pattern = /[^\sА-Яа-яЁё]/;
export const login_pattern = /[A-Za-z0-9]/;
export const login_with_symbols_pattern = /[A-Za-z0-9@#$\-_=;:<>`~!"№%^?&*()+\\|/.,'[\]{}]/;
export const external_system_login = /[^\s]/;
export const name_pattern = /[А-Яа-яЁё -]/;
export const number_pattern = /[0-9]/;
export const code_pattern = /[A-Z]/;
export const float_pattern = /[0-9.]/;
export const date_full_ISO_pattern = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d[.:][0-5]\d|Z)$/;
export const date_ISO_pattern = /^\d{4}-[01]\d-[0-3]\d$/;
export const ip4regexp: RegExp = /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/;
export const ip6regexp: RegExp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

export enum AUTH_SYSTEM_PARAMETER_NAME {
  DEVELOPER_PHONE = 'DEVELOPER_PHONE',
  DEVELOPER_EMAIL = 'DEVELOPER_EMAIL',
  PHONE_LENGTH = 'PHONE_LENGTH',
  LOG_LIFETIME = 'LOG_LIFETIME',
  PASSWORD_MAX_LIFETIME = 'PASSWORD_MAX_LIFETIME',
  PASSWORD_MIN_LIFETIME = 'PASSWORD_MIN_LIFETIME',
  PASSWORD_MIN_NUM_CHANGING_CHARACTERS = 'PASSWORD_MIN_NUM_CHANGING_CHARACTERS',
  PASS_COMPLEXITY_LENGTH = 'PASS_COMPLEXITY_LENGTH',
  PASS_COMPLEXITY_LOWERCASES = 'PASS_COMPLEXITY_LOWERCASES',
  PASS_COMPLEXITY_NUMBERS = 'PASS_COMPLEXITY_NUMBERS',
  PASS_COMPLEXITY_SPECIAL_CHARACTERS = 'PASS_COMPLEXITY_SPECIAL_CHARACTERS',
  PASS_COMPLEXITY_UPPERCASES = 'PASS_COMPLEXITY_UPPERCASES',
  LOGIN_TRY_COUNT = 'LOGIN_TRY_COUNT'
}

export enum SUBSYSTEM {
  ASUER = 'ASUER',
  GKH = 'GKH'
}

export const MENU = [
  {
    id: "m_User",
    code: "m_User",
    name: "Пользователи",
    sortOrder: 1,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: null
  },
  {
    id: "m_System",
    code: "m_System",
    name: "Системные параметры",
    sortOrder: 2,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: null
  },
  {
    id: "m_EventLog",
    code: "m_EventLog",
    name: "Мониторинг",
    sortOrder: 3,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: null
  },
  {
    id: "m_NSI",
    code: "m_NSI",
    name: "НСИ",
    sortOrder: 4,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: null
  }
];

export enum NSI_DICTIONARY_CODE {
  SUBSYSTEMS = "SUBSYSTEMS",
  KAFKA_BROKER_VERSIONS = "KAFKA_BROKER_VERSION",
  LEGAL_ENTITY = "LEGAL_ENTITY",
  DEPARTMENT = "DEPARTMENT",
  JOB_POSITION = "JOB_POSITION",
  EXTERNAL_SYSTEM_TYPE = "EXTERNAL_SYSTEM_TYPE",
  EXTERNAL_SYSTEM = "EXTERNAL_SYSTEM"
}

export const DICTIONARIES: IAdminNsiDictionary[] = [
  {
    code: NSI_DICTIONARY_CODE.SUBSYSTEMS,
    name: "Подсистемы",
    readonly: false
  },
  {
    code: NSI_DICTIONARY_CODE.KAFKA_BROKER_VERSIONS,
    name: "Версии протокола брокера kafka",
    readonly: true
  },
  {
    code: NSI_DICTIONARY_CODE.LEGAL_ENTITY,
    name: "Юридическое лицо",
    readonly: false
  },
  {
    code: NSI_DICTIONARY_CODE.DEPARTMENT,
    name: "Подразделение",
    readonly: false
  },
  {
    code: NSI_DICTIONARY_CODE.JOB_POSITION,
    name: "Должность",
    readonly: false
  },
  {
    code: NSI_DICTIONARY_CODE.EXTERNAL_SYSTEM_TYPE,
    name: "Тип внешней системы аутентификации",
    readonly: true
  },
  {
    code: NSI_DICTIONARY_CODE.EXTERNAL_SYSTEM,
    name: "Внешние системы аутентификации",
    readonly: false
  },
];

export enum GRID_ID {
  "ADMIN.USERS" = "auth-admin.users",
  "ADMIN.USERS.IP_ADDRESS" = "auth-admin.users.ip_address",
  "ADMIN.SUBSYSTEMS" = "auth-admin.subsystems",
  "ADMIN.KAFKA_BROKER_VERSIONS" = "auth-admin.kafka-broker-versions",
  "ADMIN.LEGAL_ENTITY" = "auth-admin.legal_entity",
  "ADMIN.DEPARTMENT" = "auth-admin.department",
  "ADMIN.JOB_POSITION" = "auth-admin.job_position",
  "ADMIN.SYSTEM_PARAMETERS" = "auth-admin.system_parameters",
  "ADMIN.EXTERNAL_SYSTEM_TYPE" = "auth-admin.external_system_type",
  "ADMIN.EXTERNAL_SYSTEM" = "auth-admin.external_system",
  "EVENT_LOG" = "auth-admin.event_log"
}

export enum EventLogReportCode {
  IP_ACTIONS = "IP_ACTIONS", // Отчет действий с выбранного IP
  USER_AUTH_ATTEMPTS = "USER_AUTH_ATTEMPTS", // Отчет попыток аутентификации пользователя
  USER_ACTIONS = "USER_ACTIONS", // Отчет действий пользователя
  SECURITY_ACTIONS = "SECURITY_ACTIONS", // Отчет по событиям безопасности
  USER_LIST = "USER_LIST", // Список пользователей
  ORG_AUTH_ATTEMPTS = "ORG_AUTH_ATTEMPTS" // Отчет попыток аутентификации пользователей организации
}

export enum BrokerType {
  Kafka = 'KAFKA',
  RabbitMQ = 'RABBIT_MQ'
}
