import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { share } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  IGridExportParams,
  IPagedResponse,
  IUniversalGridRequest,
  IUniversalGuideInfo,
  IUniversalGuideUpdateRequest
} from "web-frontend-component-library/grid/interfaces";
import { UniversalGridApiService } from "web-frontend-component-library/grid/services/universal-grid-api";

@Injectable()
export class AuthUniversalGridApiService implements UniversalGridApiService {

  constructor(private HttpClient: HttpClient) { }

  public getInfo(code: string): Observable<IUniversalGuideInfo> {
    return this.HttpClient.get<IUniversalGuideInfo>(`${environment.API_URL}/nsi/object/info?code=${code}`).pipe(share());
  }

  public getGrid(requestPayload: IUniversalGridRequest): Observable<IPagedResponse> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/nsi/object/grid`,
      requestPayload
    ).pipe(share());
  }

  public getRow(code: string, id: number): Observable<any> {
    return this.HttpClient.get<any>(`${environment.API_URL}/nsi/object/row?code=${code}&id=${id}`).pipe(share());
  }

  public addRow(request: IUniversalGuideUpdateRequest): Observable<any> {
    return this.HttpClient.post(`${environment.API_URL}/nsi/object`, request).pipe(share());
  }

  public updateRow(request: IUniversalGuideUpdateRequest): Observable<any> {
    return this.HttpClient.put(`${environment.API_URL}/nsi/object`, request).pipe(share());
  }

  public deleteRow(code: string, id: number): Observable<any> {
    return this.HttpClient.delete(`${environment.API_URL}/nsi/object?code=${code}&id=${id}`).pipe(share());
  }

  public getGridReport(body: IGridExportParams): void {
    // TODO в данный момент отчеты в апи DC недоступны.
  }
}
