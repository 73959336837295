import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseAuthService } from 'web-frontend-component-library/services/auth';
import { LocalStorageService } from 'web-frontend-component-library/services';
import { ITokenResponse } from 'web-frontend-component-library/interfaces';

@Injectable()
export abstract class AuthService
  extends BaseAuthService {

  constructor(
    protected localStorageService: LocalStorageService
  ) {
    super(localStorageService);
  }

  /**
   * Вход в систему
   * @param username логин
   * @param password пароль
   */
  abstract login(username: string, password: string, externalSystemId?: number): Observable<ITokenResponse>;
  abstract refreshToken(): Observable<ITokenResponse>;
}
