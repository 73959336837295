<div class="eventLogCardClose" (click)="close()">
  <span class="font-icons icon-calendar_clear"></span>
</div>
<div class="eventLogCardClass">
  <div class="modal-header">
    <div class="event-log-card-title">{{eventTypeTitle}}</div>
  </div>
  <div class="modal-body">
    <div class="event-log-card-block scroll-block">
      <div class="event-log-card-block-content">
      <div class="event-log-card-item">
        <div class="event-log-card-item-title" (click)="blocks[0].open = !blocks[0].open">
          <div class="event-log-card-item-text">{{blocks[0].title}}</div>
          <div class="event-log-card-item-title-icon">
            <span class="font-icons-auth icon-str_up" [class.str_down]="!blocks[0].open"></span>
          </div>
        </div>
        <div class="event-log-card-item-content" [class.open]="blocks[0].open">
          <div class="event-log-card-form">
          <div class="event-log-card-form-row">
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.ID_EVENT' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{info.id}}</div>
              </div>
            </div>
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.ID_OBJECT' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{info.object_id}}</div>
              </div>
            </div>
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.TYPE_OBJECT' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{info.object_type_name}}</div>
              </div>
            </div>
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.AUTH_USER' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{info.object_user_login}}</div>
              </div>
            </div>
          </div>
          <div class="event-log-card-form-row">
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.ID_EXT_OBJECT' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{info.related_id}}</div>
              </div>
            </div>
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.TYPE_EXT_OBJECT' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{info.related_type_name}}</div>
              </div>
            </div>
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.TYPE_EVENT' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{info.event_type_name}}</div>
              </div>
            </div>
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.CATEGORY_EVENT' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{info.event_category_name}}</div>
              </div>
            </div>
          </div>
          <div class="event-log-card-form-row">
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.START_DATE' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{startDate}}</div>
              </div>
            </div>
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.END_DATE' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{endDate}}</div>
              </div>
            </div>
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.ERROR_COUNT' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value"
                     [class.red]="info && info.error_cnt && info.error_cnt > 0">{{info.error_cnt}}</div>
              </div>
            </div>
            <div class="event-log-card-form-item">
              <div class="event-log-card-form-item-label">
                <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.COMMENT' | translate}}</span>
              </div>
              <div class="event-log-card-form-item-field">
                <div class="event-log-card-form-item-value">{{info.event_comment}}</div>
              </div>
            </div>
          </div>
            <div class="event-log-card-form-row">
              <div class="event-log-card-form-item">
                <div class="event-log-card-form-item-label">
                  <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.USER' | translate}}</span>
                </div>
                <div class="event-log-card-form-item-field">
                  <div class="event-log-card-form-item-value">{{info.user_start}}</div>
                </div>
              </div>
              <div class="event-log-card-form-item">
                <div class="event-log-card-form-item-label">
                  <span class="form-field-label">{{'ADMIN.EVENT_LOG.CARD.FIELDS.IP' | translate}}</span>
                </div>
                <div class="event-log-card-form-item-field">
                  <div class="event-log-card-form-item-value">{{info.ip_address}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="changes && changes.length > 0" class="event-log-card-item">
        <div class="event-log-card-item-title" (click)="blocks[1].open = !blocks[1].open">
          <div class="event-log-card-item-text">{{blocks[1].title}}</div>
          <div class="event-log-card-item-title-icon">
            <span class="font-icons-auth icon-str_up" [class.str_down]="!blocks[1].open"></span>
          </div>
        </div>
        <div class="event-log-card-item-content" [class.open]="blocks[1].open">
          <div class="event-log-card-table">
            <div class="event-log-card-table-header">
              <div class="event-log-card-table-header-item">
                {{'ADMIN.EVENT_LOG.CARD.CHANGED_ATTRIBUTES.GRID_COLS.NAME' | translate}}
              </div>
              <div class="event-log-card-table-header-item">
                {{'ADMIN.EVENT_LOG.CARD.CHANGED_ATTRIBUTES.GRID_COLS.START_VALUE' | translate}}
              </div>
              <div class="event-log-card-table-header-item">
                {{'ADMIN.EVENT_LOG.CARD.CHANGED_ATTRIBUTES.GRID_COLS.END_VALUE' | translate}}
              </div>
            </div>
            <div class="event-log-card-table-body">
              <div class="event-log-card-table-body-block scroll-block">
                <div *ngFor="let item of changes" class="event-log-card-table-row">
                  <div class="event-log-card-table-row-item">{{item.field}}</div>
                  <div class="event-log-card-table-row-item">{{item.change_from}}</div>
                  <div class="event-log-card-table-row-item">{{item.change_to}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="info && info.error_cnt > 0" class="event-log-card-item">
        <div class="event-log-card-item-title" (click)="blocks[2].open = !blocks[2].open">
          <div class="event-log-card-item-text">{{blocks[2].title}}</div>
          <div class="event-log-card-item-title-icon">
            <span class="font-icons-auth icon-str_up" [class.str_down]="!blocks[2].open"></span>
          </div>
        </div>
        <div class="event-log-card-item-content" [class.open]="blocks[2].open">
          <div class="event-log-card-table errors">
            <div class="event-log-card-table-header">
              <div class="event-log-card-table-header-item">
                {{'ADMIN.EVENT_LOG.CARD.ERRORS_GRID.GRID_COLS.CATEGORY' | translate}}
              </div>
              <div class="event-log-card-table-header-item">
                {{'ADMIN.EVENT_LOG.CARD.ERRORS_GRID.GRID_COLS.TYPE' | translate}}
              </div>
              <div class="event-log-card-table-header-item">
                {{'ADMIN.EVENT_LOG.CARD.ERRORS_GRID.GRID_COLS.TEXT' | translate}}
              </div>
              <div class="event-log-card-table-header-item">
                {{'ADMIN.EVENT_LOG.CARD.ERRORS_GRID.GRID_COLS.DATE' | translate}}
              </div>
            </div>
            <div class="event-log-card-table-body">
              <div class="event-log-card-table-body-block scroll-block">
                <div *ngFor="let item of errors" class="event-log-card-table-row">
                  <div class="event-log-card-table-row-item">{{item.category_name}}</div>
                  <div class="event-log-card-table-row-item">{{item.type_name}}</div>
                  <div class="event-log-card-table-row-item">{{item.error_text}}</div>
                  <div class="event-log-card-table-row-item">{{item.error_date}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
