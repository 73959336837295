import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ElementRef,
  ViewChild,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITextMaskConfig } from 'web-frontend-component-library/interfaces';
import { fromEvent, interval, merge } from 'rxjs';
import {skip, take, takeUntil} from 'rxjs/operators';
import { BaseInputComponent } from 'web-frontend-component-library/components/base-components';

@Component({
  selector: 'app-input-auth',
  templateUrl: './input-auth.component.html',
  styleUrls: ['./input-auth.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAuthComponent),
      multi: true
    }
  ]
})
export class InputAuthComponent
  extends BaseInputComponent
  implements OnInit, OnChanges, ControlValueAccessor {

  private innerValue: any;
  public maskInputConfigs = {};

  /**
   * Признак замены символов
   */
  @Input() withReplace: boolean = false;
  /**
   * ID инпута
   */
  @Input() inputId: string = '';
  @Input() iconClass: string; // класс иконки
  @Input() iconSize: number; // размер иконки
  @Input() iconColor: string | null = null; // цвет иконки
  /**
   * Высота инпута
   */
  @Input() inputHeight: number = 35;
  /**
   * Тип инпута
   */
  @Input() type: string = 'text';
  /**
   * Флаг отображения позитивного числа с +
   */
  @Input() positiveNumberWithPlus: boolean = false;
  /**
   * Шаг для ввода чисел
   */
  @Input() step: number = 1;
  /**
   * Максимальное значение для числового поля
   */
  @Input() maxValue: number = null;
  /**
   * Минимальное значение для числового поля
   */
  @Input() minValue: number = null;
  /**
   * Блокировка анимации плейсхолдера (обычный инпут)
   */
  @Input() disablePlaceholderAnimation = false;
  /**
   * Макса ввода
   */
  @Input() mask: any[] | Function | boolean;
  /**
   * Конфигурация маски ввода
   */
  @Input() textMaskConfigs: ITextMaskConfig;
  /**
   * Паттерн ввода
   */
  @Input() pattern: RegExp;
  /**
   * Паттерн ввода для ограничения ввода
   */
  @Input() patternValue: RegExp;
  /**
   * Кол-во знаков после запятой
   */
  @Input() maxLengthFloat: number = null;
  /**
   * Спрятать стрелки на числовом инпуте.
   * @default false
   */
  @Input() hideArrows: boolean = false;

  @ViewChild('input') input: ElementRef;

  get model(): string {
    return this.innerValue;
  }

  set model(val) {
    if (this.withReplace && this.type === 'text') {
      val = this.replaceSymbol(val, this.innerValue);
    }
    if (val !== this.innerValue) {
      this.innerValue = this.checkMask(val);;
      this.input.nativeElement.value = this.innerValue;
      this.emitChanges(this.innerValue);
    }
  }

  ngOnInit() {
    if (!this.mask) {
      this.mask = false;
    }
    if (this.textMaskConfigs) {
      this.maskInputConfigs = { ...this.textMaskConfigs };
    } else {
      this.maskInputConfigs = {
        showMask: false,
        mask: this.mask,
        keepCharPositions: true,
        guide: false
      };
    }
    if (this.type === 'number') {
      this.pattern = this.pattern ? this.pattern : /^[\d.,]*$/;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mask || changes.textMaskConfigs) {
      if (!this.mask) {
        this.mask = false;
      }
      if (this.textMaskConfigs) {
        this.maskInputConfigs = { ...this.textMaskConfigs };
      } else {
        this.maskInputConfigs = {
          showMask: false,
          mask: this.mask,
          keepCharPositions: true,
          guide: false
        };
      }
    }
  }

  writeValue(value: any): void {
    this.innerValue = this.checkMask(value);
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.type === 'number') {
      const previousValue: string = this.input.nativeElement.value;
      // mozilla, edge, safari и explorer некорректно обрабатывают <input type=number>, для этого ограничиваем ввод символов
      if (!event.key ||
        !/([\d,.\+-])|(Backspace|Delete|ArrowLeft|ArrowRight)/.test(event.key) ||
        (/[,.\+-]/.test(event.key) && previousValue.includes(event.key))) {
        event.preventDefault();
      }
    }
  }

  onInput() {
    if (this.type === 'number') {
      const regexp = /[\+-]/g;
      const value: string = this.input.nativeElement.value ? this.input.nativeElement.value : '';
      const match = value.match(regexp);
      const mark = match && match.length > 0 ? match[0] : '';
      this.model = mark + value.replace(regexp, '');
    }
  }

  public arrowPressed($event: Event, name: 'increase' | 'decrease') {
    const callback = name === 'increase'
      ? this.increase
      : this.decrease;
    const cancel$ = merge(
      fromEvent(window, 'mouseup'),
      fromEvent($event.target, 'mouseleave')
    ).pipe(take(1));

    callback.call(this);
    interval(100)
      .pipe(
        skip(1),
        takeUntil(cancel$)
      )
      .subscribe(callback.bind(this));
  }

  private increase() {
    // фикс "неточные вычисления" 0.2 + 0.1 = 0.30000000000000004
    let newValue = this.innerValue ? this.getMappedNewNumberValue(+this.innerValue.replace(',', '.') + this.step) : 0;
    if (this.maxValue !== null && this.maxValue !== undefined) {
      newValue = this.maxValue < newValue ? this.maxValue : newValue;
    }
    this.model = newValue.toString();
  }

  private decrease() {
    // фикс "неточные вычисления"  0.2 + 0.1 = 0.30000000000000004
    let newValue = this.innerValue ? this.getMappedNewNumberValue(+this.innerValue.replace(',', '.') - this.step) : 0;
    if (this.minValue !== null && this.minValue !== undefined) {
      newValue = this.minValue > newValue ? this.minValue : newValue;
    }
    this.model = newValue.toString();
  }

  private get stepDecimalPlace(): number {
    return this.step.toString().split('.')[1] ? this.step.toString().split('.')[1].length : 0;
  }

  private getMappedNewNumberValue(value: number): number {
    return parseFloat((value).toFixed(this.stepDecimalPlace));
  }

  private checkMask(value: string): string {
    let result: string = '';
    let val: string = '';
    if (value && value.toString()) {
      if (this.patternValue) {
        for (let i = 0; i < value.length; i++) {
          if (value.charAt(i).match(this.patternValue)) {
            val += value.charAt(i);
          }
        }
      } else {
        val = value.toString();
      }
      if (this.type === 'number') {
        // убираем лишние символы после точки
        if ((!!this.maxLengthFloat || this.maxLengthFloat === 0) && val.length > 0) {
          let substrs = val.split('.');
          if (substrs && substrs.length > 1 && substrs[1].length > this.maxLengthFloat) {
            val = this.maxLengthFloat > 0
              ? `${substrs[0]}.${substrs[1].substring(0, this.maxLengthFloat)}`
              : `${substrs[0]}`;
          }
        }
        // добавляем + к положительному числу
        if (this.positiveNumberWithPlus && !val.includes('+') && +val > 0) {
          val = '+' + val;
        }
      }
      if (this.maxLength) {
        result = val && val.length > Number(this.maxLength) ? val.substring(0, Number(this.maxLength)) : val;
      } else {
        result = val;
      }
    }
    return result;
  }

  private replaceSymbol(currentValue: string, beforeValue: string): string {
    let result = currentValue;
    let index: number = null;
    for (let i = 0; i < currentValue.length; i++) {
      if (!!beforeValue.charAt(i) && currentValue.charAt(i) !== beforeValue.charAt(i)) {
        index = i;
        break;
      }
    }
    if (index !== null) {
      result = beforeValue.replace(beforeValue.charAt(index), currentValue.charAt(index));
    }
    return result;
  }
}
