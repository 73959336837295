import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { SystemParamsService } from "@core/services/system-params.service";
import { AUTH_SYSTEM_PARAMETER_NAME } from "@app/app.enums";
import { INotifications, IUserInfo } from "@core/interfaces/user";
import { OpenModalService } from "web-frontend-component-library/services";
import { UserService } from "@core/services/user.service";
import { TranslateService } from "@ngx-translate/core";
import { ChangePasswordModalComponent } from "@shared/components/change-password-modal/change-password-modal.component";
import { ConfirmModalComponent } from "web-frontend-component-library/components/confirm-modal";
import { FormControl, FormGroup } from "@angular/forms";
import { UserApiService } from "@core/services/api/user-api.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-header-support',
  templateUrl: './header-support.component.html',
  styleUrls: ['./header-support.component.scss']
})
export class HeaderSupportComponent implements OnInit, OnDestroy {

  public user: IUserInfo;
  public userHasEmail: boolean = false;
  public supportPhone: string;
  public supportEmail: string;
  public showSupportBalloon: boolean = false;
  public showSettingsBalloon: boolean = false;
  public showUserBalloon: boolean = false;
  private exitTitles: string[];
  public isLoading: boolean = false; // флаг загрузки
  private publicSystemParamsSubscription: Subscription = new Subscription();

  // форма Настройка уведомлений
  public settingsForm: FormGroup = new FormGroup({
    isNotificationLoginFail: new FormControl(false),
    isNotificationLoginSuccess: new FormControl(false),
    isNotificationLogout: new FormControl(false)
  });

  constructor(
    private modalService: OpenModalService,
    private translateService: TranslateService,
    private userService: UserService,
    private systemParamsService: SystemParamsService,
    private userApiService: UserApiService,
    private toastr: ToastrService,
  ) {
    this.translateService.get(['GENERAL.LOGOUT_QUESTION', 'GENERAL.EXIT'])
      .subscribe((result: string[]) => {
        this.exitTitles = result;
      });
  }

  ngOnInit() {
    this.user = this.userService.userInfo;
    this.userHasEmail = this.user && this.user.email && this.user.email !== '';
    this.prepareSettingsForm();
    this.publicSystemParamsSubscription = this.systemParamsService.getPublicSystemParams()
      .subscribe((systemParams: any) => {
        this.supportPhone = systemParams ? systemParams[AUTH_SYSTEM_PARAMETER_NAME.DEVELOPER_PHONE] : '';
        this.supportEmail = systemParams ? systemParams[AUTH_SYSTEM_PARAMETER_NAME.DEVELOPER_EMAIL] : '';
      });
  }

  ngOnDestroy() {
    this.publicSystemParamsSubscription.unsubscribe();
  }

  /**
   * подготовка формы Настройка уведомлений
   */
  private prepareSettingsForm() {
    this.settingsForm.patchValue({
      isNotificationLoginFail: this.user && this.user.is_notification_login_fail
        ? this.user.is_notification_login_fail : false,
      isNotificationLoginSuccess: this.user && this.user.is_notification_login_success
        ? this.user.is_notification_login_success : false,
      isNotificationLogout: this.user && this.user.is_notification_logout
        ? this.user.is_notification_logout : false
    });
  }

  setSettingsBalloonOpened(value: boolean) {
    this.showSettingsBalloon = value;
  }

  setUserBalloonOpened(value: boolean) {
    this.showUserBalloon = value;
  }

  setSupportBalloonOpened(value: boolean) {
    this.showSupportBalloon = value;
  }

  public changePassword() {
    this.setUserBalloonOpened(false);
    this.modalService.show({
      component: ChangePasswordModalComponent,
      data: {},
      options: {
        backdrop: 'static',
        centered: true,
        windowClass: 'modal-standart password-modal',
      },
      callbacks: {
        Escape: 'onCancel'
      }
    });
  }

  public exit() {
    this.setUserBalloonOpened(false);
    this.modalService.show({
      component: ConfirmModalComponent,
      data: {
        question: this.exitTitles['GENERAL.LOGOUT_QUESTION'],
        applyTitle: this.exitTitles['GENERAL.EXIT'],
        onApply: () => {
          this.userService.logout();
        }
      },
      options: {
        centered: true,
        windowClass: 'modal-confirm',
      },
      callbacks: {
        Enter: 'apply',
        Escape: 'cancel'
      }
    });
  }

  public changeField(value: any, field: string) {
    this.saveNotifications();
  }

  private getNotificationParams(): INotifications {
    return {
      is_notification_login_fail: this.settingsForm.get('isNotificationLoginFail').value
        ? this.settingsForm.get('isNotificationLoginFail').value : false,
      is_notification_login_success: this.settingsForm.get('isNotificationLoginSuccess').value
        ? this.settingsForm.get('isNotificationLoginSuccess').value : false,
      is_notification_logout: this.settingsForm.get('isNotificationLogout').value
        ? this.settingsForm.get('isNotificationLogout').value : false
    }
  }

  private saveNotifications() {
    let params: INotifications = this.getNotificationParams();
    this.isLoading = true;
    this.userApiService.updateUserNotifications(params).subscribe((result) => {
      this.isLoading = false;
      this.userService.setUserNotifications(params);
    }, (error) => {
      this.prepareSettingsForm();
      this.isLoading = false;
      if (error.error && error.error['message']) {
        this.toastr.error(error.error['message']);
      }
    });
  }
}
