import { applyColorscheme } from "web-frontend-component-library/module";
import { EnvService, ColorschemeName } from "../services/env.service";
import { colorschemeBlue, colorschemeRed, colorschemeYellow } from "web-frontend-component-library/colorscheme";

export const colorschemeInitializer = (envService: EnvService) => {
    return (): Promise<void> => {
        return new Promise(resolve => {
            if (envService.colorscheme) {
                applyColorscheme({ colorscheme: getColorscheme(envService.colorscheme) });
            }
            resolve();
        })
    }
}

const getColorscheme = (name: ColorschemeName) => {
    switch (name) {
        case 'blue':
            return colorschemeBlue;
        case 'yellow':
            return colorschemeYellow;
        case 'red':
            return colorschemeRed;
        default:
            return colorschemeBlue;
    }
}