import { AUTH_KEY } from "@app/app.enums";
import { TokenSyncConfig } from "web-frontend-component-library/services/token-sync";
import { AuthService } from "../services/auth/auth.service";

export function tokenSyncConfigFactory(
    authService: AuthService
): TokenSyncConfig {
    return {
        tokenLocalStorageKey: AUTH_KEY,
        tokenBroadcastChannelKey: AUTH_KEY,
        getTokens: () => authService.tokenResponse,
        onChangeTokens: tokens => {
            authService.tokenResponse = tokens;
        },
        observeTokens: () => authService.observeTokenResponse()
    }
}