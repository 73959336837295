import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'web-frontend-component-library/services';
import { tap, catchError } from 'rxjs/operators';
import { ITokenResponse } from 'web-frontend-component-library/interfaces';
import { AUTH_KEY } from '@app/app.enums';

const GRANT_TYPE_PASSWORD: string = "password";
const GRANT_TYPE_REFRESH_TOKEN: string = "refresh_token";

@Injectable()
export class OAuth2Service extends AuthService {

  protected readonly tokensStorageKey: string = AUTH_KEY;

  constructor(
    private httpClient: HttpClient,
    protected localStorageService: LocalStorageService
  ) {
    super(localStorageService);
    this.tokenResponse$.next(this.getTokenResponseFromStorage());
  }

  public login(username: string, password: string, externalSystemId?: number): Observable<ITokenResponse> {
    this.clearCredentials();
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Basic ${this.getAppAuthorizationCode()}`
    });
    let authPayload = new FormData();
    authPayload.append("grant_type", GRANT_TYPE_PASSWORD);
    authPayload.append("username", username);
    authPayload.append("password", password);
    if (externalSystemId) {
      authPayload.append("external_system_id", externalSystemId.toString());
    }
    return this.httpClient.post<ITokenResponse>(`${environment.AUTH_URL}/oauth/token`, authPayload, { headers, withCredentials: true })
      .pipe(tap(res => this.setCredentials(res)));
  }

  public logout() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`
    });
    return this.httpClient.post(`${environment.AUTH_URL}/oauth/revoke`, {}, { headers })
      .pipe(tap(() => this.clearCredentials()));
  }

  public refreshToken(): Observable<ITokenResponse> {
    const formData = new FormData();
    formData.append("grant_type", GRANT_TYPE_REFRESH_TOKEN);
    formData.append("refresh_token", this.tokenResponse?.refresh_token);
    return this.httpClient.post<ITokenResponse>(`${environment.AUTH_URL}/oauth/token`, formData)
      .pipe(
        tap(res => this.setCredentials(res)),
        catchError(error => {
          this.clearCredentials();
          return throwError(error);
        })
      );
  }

  public getAppAuthorizationCode(): string {
    return btoa(`${environment.AUTH_USERNAME}:${environment.AUTH_PASSWORD}`);
  }
}
