import { RouterModule } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { TextMaskModule } from "angular2-text-mask";
import { ContextMenuModule } from "ngx-contextmenu";
import { ChangePasswordModalComponent } from "@shared/components/change-password-modal/change-password-modal.component";
import { HeaderSupportComponent } from '@shared/components/header-support/header-support.component';
import { HeaderComponent } from "@shared/components/header/header.component";
import { ChangePasswordFormComponent } from '@shared/components/change-password-modal/change-password-form/change-password-form.component';
import { TemporaryPasswordModalComponent } from "@shared/components/temporary-password-modal/temporary-password-modal.component";
import { RestoreAccessFormComponent } from "@shared/components/restore-access-form/restore-access-form.component";
import { ExitComponent } from "@shared/components/exit/exit.component";
import * as dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsFormats from 'dayjs/plugin/customParseFormat';
import * as dayjsRu from 'dayjs/locale/ru';
import * as dayjsQuarter from 'dayjs/plugin/quarterOfYear';
import * as dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as dayjsDuration from 'dayjs/plugin/duration';
import * as dayjsArraySupport from 'dayjs/plugin/arraySupport';
import { AccordionItemModule } from 'web-frontend-component-library/components/accordion-item';
import { ActionMenuModule } from 'web-frontend-component-library/components/action-menu';
import { BasePageWithChildsModule } from 'web-frontend-component-library/components/base-page-with-childs';
import { BreadcrumbsModule } from 'web-frontend-component-library/components/breadcrumbs';
import { ButtonModule } from 'web-frontend-component-library/components/button';
import { CalendarModule } from 'web-frontend-component-library/components/calendar';
import { CheckboxModule } from 'web-frontend-component-library/components/checkbox';
import { CheckboxListModule } from 'web-frontend-component-library/components/checkbox-list';
import { ConfirmModalModule } from 'web-frontend-component-library/components/confirm-modal';
import { DownloadProgressModule } from 'web-frontend-component-library/components/download-progress';
import { FilesListModule } from 'web-frontend-component-library/components/files-list';
import { InputModule } from 'web-frontend-component-library/components/input';
import { LockEditableModule } from 'web-frontend-component-library/components/lock-editable';
import { LoaderModule } from 'web-frontend-component-library/components/loader';
import { MultiDictionaryModule } from 'web-frontend-component-library/components/multi-dictionary';
import { MultiSelectModule } from 'web-frontend-component-library/components/multi-select';
import { PasswordInputModule } from 'web-frontend-component-library/components/password-input';
import { RadioboxListModule } from 'web-frontend-component-library/components/radiobox-list';
import { RoundProgressBarModule } from 'web-frontend-component-library/components/round-progress-bar';
import { SearchFieldModule } from 'web-frontend-component-library/components/search-field';
import { SelectSearchListModule } from 'web-frontend-component-library/components/select-search-list';
import { SimpleDictionaryModule } from 'web-frontend-component-library/components/simple-dictionary';
import { SimpleSelectModule } from 'web-frontend-component-library/components/simple-select';
import { SwitchModule } from 'web-frontend-component-library/components/switch';
import { TabsModule } from 'web-frontend-component-library/components/tabs';
import { TextareaModule } from 'web-frontend-component-library/components/textarea';
import { ToastrModule } from 'web-frontend-component-library/components/toastr';
import { UniversalTreeModule } from 'web-frontend-component-library/components/universal-tree';
import { UniversalTreeItemModule } from 'web-frontend-component-library/components/universal-tree-item';
import { DisableControlModule } from 'web-frontend-component-library/directives/disable-control';
import { FormatDatePipeModule } from 'web-frontend-component-library/pipes/format-date';
import { IsVisiblePipeModule } from 'web-frontend-component-library/pipes/is-visible';
import { RouteParamDirectiveModule } from 'web-frontend-component-library/directives/route-param';
import { EventLogCardComponent } from "@shared/components/event-log-card/event-log-card.component";
import { SearchFieldAuthComponent } from "@shared/components/search-field-auth/search-field-auth.component";
import { InputAuthComponent } from "@shared/components/input-auth/input-auth.component";
import { MenuAuthComponent } from "@shared/components/menu-auth/menu-auth.component";
import { NoSelectStubModule } from 'web-frontend-component-library/components/no-select-stub';
import { FocusTrapModule } from 'web-frontend-component-library/directives/focus-trap'
import { PopupContainerModule } from 'web-frontend-component-library/components/popup-container'

dayjs.extend(dayjsQuarter);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsArraySupport);
dayjs.locale(dayjsRu);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    ContextMenuModule,
    ClickOutsideModule,
    QuicklinkModule,
    // lib
    AccordionItemModule,
    ActionMenuModule,
    BasePageWithChildsModule,
    BreadcrumbsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    CheckboxListModule,
    ConfirmModalModule,
    DownloadProgressModule,
    FilesListModule,
    InputModule,
    LoaderModule,
    LockEditableModule,
    MultiDictionaryModule,
    MultiSelectModule,
    PasswordInputModule,
    RadioboxListModule,
    RoundProgressBarModule,
    SearchFieldModule,
    SelectSearchListModule,
    SimpleDictionaryModule,
    SimpleSelectModule,
    SwitchModule,
    TabsModule,
    TextareaModule,
    ToastrModule,
    UniversalTreeModule,
    UniversalTreeItemModule,
    DisableControlModule,
    FormatDatePipeModule,
    IsVisiblePipeModule,
    RouteParamDirectiveModule,
    NoSelectStubModule,
    FocusTrapModule,
    PopupContainerModule,
  ],
  declarations: [
    ChangePasswordModalComponent,
    HeaderSupportComponent,
    HeaderComponent,
    ChangePasswordFormComponent,
    TemporaryPasswordModalComponent,
    RestoreAccessFormComponent,
    ExitComponent,
    EventLogCardComponent,
    SearchFieldAuthComponent,
    InputAuthComponent,
    MenuAuthComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    NgbModule,
    QuicklinkModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    TranslateModule,
    ContextMenuModule,
    // lib
    AccordionItemModule,
    ActionMenuModule,
    BasePageWithChildsModule,
    BreadcrumbsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    CheckboxListModule,
    ConfirmModalModule,
    DownloadProgressModule,
    FilesListModule,
    InputModule,
    LoaderModule,
    LockEditableModule,
    MultiDictionaryModule,
    MultiSelectModule,
    PasswordInputModule,
    RadioboxListModule,
    RoundProgressBarModule,
    SearchFieldModule,
    SelectSearchListModule,
    SimpleDictionaryModule,
    SimpleSelectModule,
    SwitchModule,
    TabsModule,
    TextareaModule,
    ToastrModule,
    UniversalTreeModule,
    UniversalTreeItemModule,
    DisableControlModule,
    FormatDatePipeModule,
    IsVisiblePipeModule,
    RouteParamDirectiveModule,
    NoSelectStubModule,
    FocusTrapModule,
    PopupContainerModule,
    // components
    ChangePasswordModalComponent,
    HeaderSupportComponent,
    HeaderComponent,
    ChangePasswordFormComponent,
    TemporaryPasswordModalComponent,
    RestoreAccessFormComponent,
    ExitComponent,
    EventLogCardComponent,
    InputAuthComponent,
    SearchFieldAuthComponent,
    MenuAuthComponent
  ],
  entryComponents: [
    ChangePasswordModalComponent,
    TemporaryPasswordModalComponent,
    RestoreAccessFormComponent,
    EventLogCardComponent
  ]
})
export class SharedModule { }
